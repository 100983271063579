import React, {
  useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  matchPath,
  Navigate, useParams, useLocation, useNavigate,
} from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import Hotjar from '@hotjar/browser'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { withStyles } from '~/themes/useStyles'
import { useSyncedSignOut } from '~/modules/auth/utils/syncedSignOut'
import { isActivePointReward, isValidUser } from '~/modules/auth/conditions'
import { useAuth } from '~/modules/auth/redux/hook'
import { useUser } from '~/redux/hooks/user'
import Guard from '~/modules/auth/components/Guard'
// import Topbar from '~/components/common/Layout/Topbar'
import style from './style'
import StoreGuard from '~/components/Guard/StoreGuard'
import RoleGuard from '~/components/Guard/RoleGuard'
import BackToTopButton from '~/components/common/BackToTop'
import PushNotification from '~/components/main/PushNotification'
import { useNotification } from '~/redux/hooks/notificationCenter'
import {
  ADMIN_ROUTES, PROMOTION_ROUTES, POINT_AND_REWARDS_ROUTERS,
} from '~/constants/router'
import { urlWithStoreID } from '~/utils/common'
// import DataLoading from '~/components/common/DataLoading'
import { goToTop } from '~/utils/scrollUp'
import useGATracking from '~/utils/hooks/useGATracking'
import { PENDO_API_KEY, HOTJAR_SITE_ID } from '~/constants/environment'
import growthbook from '~/configs/growthBook'
import { useIsSyncedStore, useStoreType } from '~/redux/hooks/store'
import { STORE_TYPES } from '~/constants/store'
import { useFetchingIncompletedOrders } from '~/redux/hooks/cantecFulfillment'
import logger from '~/utils/logger'
import UnifiedNavbar from '~/components/common/Layout/UnifiedNavbar'
import { useUserSession } from '~/utils/hooks/useUserSession'
import authApi from '~/modules/auth/services'
import { FLAGS } from '~/constants/flags'

const AdminLayout = ({
  classes, children,
}) => {
  const location = useLocation()
  useSyncedSignOut()
  useGATracking()
  const { storeId } = useParams()
  const navigate = useNavigate()
  const { actions, authState } = useAuth()
  const { actions: userActions, orgId, user: { user_settings } } = useUser()
  const { actionNotifi } = useNotification()
  const pendoRef = useRef('')
  const storeType = useStoreType(storeId)
  const [disable, setDisable] = useState(false)
  const isSyncedStore = useIsSyncedStore()

  const isUpdateLocation = useFeatureIsOn(FLAGS.BS_11583)
  const [sessionData, setSessionData] = useUserSession()

  useEffect(() => {
    if (user_settings?.default_location && isUpdateLocation) {
      setSessionData({ ...sessionData, location_id: user_settings?.default_location })
    }
  }, [user_settings?.default_location])

  const hotjarRef = useRef(null)
  const firebaseUserId = authApi.getUserFirebaseId()

  useEffect(() => {
    const params = {
      id: authState?.data?.userId,
      email: authState?.data?.userEmail,
      full_name: authState?.data?.fullName,
      org_id: orgId,
      org_name: authState?.data?.orgs ? authState?.data?.orgs[orgId]?.name : '',
      store_id: storeId,
      role: authState?.data?.stores ? authState?.data?.stores[storeId]?.roleId : '',
    }

    if (firebaseUserId && HOTJAR_SITE_ID) {
      if (isEqual(hotjarRef.current, params)) return
      hotjarRef.current = params
      Hotjar.identify(firebaseUserId, params)
    }
  }, [firebaseUserId, authState?.data?.userEmail, authState?.data?.fullName, authState?.data?.userId, authState?.data?.orgs, authState?.data?.stores, orgId, storeId])

  useEffect(() => {
    const params = {
      visitor: {
        id: authState?.data?.userId,
        email: authState?.data?.userEmail,
        full_name: authState?.data?.fullName,
        role: authState?.data?.stores ? authState?.data?.stores[storeId]?.roleId : '',
      },
      account: {
        id: orgId,
        name: authState?.data?.orgs ? authState?.data?.orgs[orgId]?.name : '',
        is_paying: false,
      },
    }
    growthbook.setAttributes({
      id: authState?.data?.userId,
      email: authState?.data?.userEmail,
      role: authState?.data?.stores ? authState?.data?.stores[storeId]?.roleId : '',
      orgId,
      storeId,
    })
    if (isEqual(pendoRef.current, params)) return
    if (PENDO_API_KEY) {
      pendoRef.current = params
      window.pendo?.initialize(params)
    }
  }, [authState, orgId, storeId])

  useEffect(() => {
    const { pathname } = location
    if (new RegExp(/media/g).test(pathname)) {
      goToTop('content-folder')
      goToTop('mediaListFile')
      setDisable(true)
    } else if (new RegExp(/(promotion|custom-report)/g).test(pathname)) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [location])

  useEffect(() => {
    logger.info('Check auth state at admin layout')
    actions.checkAuthState()
  }, [])

  useEffect(() => {
    if (orgId && storeId && storeType !== STORE_TYPES.SHOPIFY && isSyncedStore) {
      actions.getPlugins(
        {
          onSuccess: (plugins) => {
            const isInPoint = !!matchPath({ path: `${ADMIN_ROUTES.STORE}${POINT_AND_REWARDS_ROUTERS.POINT}/:id?`, exact: true }, location.pathname)
            if (isInPoint && !isActivePointReward()({ plugins })) {
              navigate(urlWithStoreID(PROMOTION_ROUTES.PROMOTION_RAW, storeId), { replace: true })
            }
          },
          onError: () => { },
        },
      )
    }
  }, [orgId, storeId, storeType, isSyncedStore])

  const getNotificationCenter = () => actionNotifi.getNotificationCenter({ limit: 20, page: 1 })

  useEffect(() => {
    if (orgId) {
      userActions.getOrg({ organizations_list: [{ organization_id: orgId }] })
    }
  }, [orgId])

  useEffect(() => {
    let refreshNotification = false
    if (storeId) {
      getNotificationCenter()
      refreshNotification = setInterval(() => {
        getNotificationCenter()
      }, 600000)
    }
    return () => {
      if (refreshNotification) clearInterval(refreshNotification)
    }
  }, [storeId])

  useFetchingIncompletedOrders(storeId)

  const handleShowUnifinedMenuMobile = () => {
    const element = document.getElementsByClassName(
      'bs-unified__side-bar-container',
    )[0]
    element?.classList.add('bs-unified__side-bar-container-open')
    element?.setAttribute('style', 'z-index: 998')
  }

  return (
    <Guard accessWhen={isValidUser} fallbackComp={<Navigate to="/login" />}>
      <div className={classes.body}>
        <UnifiedNavbar />
        <div className={classes.content}>
          {/* <Topbar /> */}
          {
            location.pathname?.includes(ADMIN_ROUTES.HOME) ? null : (
              <div className={classes.showBtnUnifined}>
                <img role="presentation" src="/icons/nav.svg" alt="nav" onClick={handleShowUnifinedMenuMobile} />
              </div>
            )
          }
          <BackToTopButton showBelow={500} disable={disable} />
          <StoreGuard>
            <RoleGuard>
              {/* <Suspense fallback={null}> */}
              <div id="content" className={classes.childrenWrap}>
                {authState.status === 'SUCCESS' && children}
              </div>
              {/* </Suspense> */}
              {/* {authState.status !== 'SUCCESS' && <DataLoading loading />} */}
              {/* {children} */}
            </RoleGuard>
          </StoreGuard>
        </div>
      </div>

      <PushNotification />
    </Guard>
  )
}

AdminLayout.propTypes = {
  classes: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
}

export default withStyles(style)(AdminLayout)
