import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SSOProvider from '../SSOProvider'
import { WrapSSOContext } from './hook'

const WrapSSOProvider = ({ children }) => {
  const [isHandlingSSO, setIsHandlingSSO] = useState(false)

  return (
    <WrapSSOContext.Provider value={{ isHandlingSSO, setIsHandlingSSO }}>
      <SSOProvider>
        {children}
      </SSOProvider>
    </WrapSSOContext.Provider>
  )
}

WrapSSOProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WrapSSOProvider
