export const ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  GENERAL_MANAGER: 'general_manager', // Old role is MANAGER
  MARKETING_MANAGER: 'marketing_manager', // Old role is MANAGER
  SERVICE_MANAGER: 'service_manager', // Old role is SALES_ASSOCIATE
  SERVICE_AGENT: 'service_agent', // Old role is SALES_ASSOCIATE

  FULFILLMENT_ASSOCIATE: 'Picking Operator', // Old role is FULFILLMENT_ASSOCIATE
}

export const ROLE_NAMES = {
  [ROLES.OWNER]: 'Owner',
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.GENERAL_MANAGER]: 'General Manager',
  [ROLES.MARKETING_MANAGER]: 'Marketing Manager',
  [ROLES.SERVICE_MANAGER]: 'Service Manager',
  [ROLES.SERVICE_AGENT]: 'Service Agent',
  [ROLES.FULFILLMENT_ASSOCIATE]: 'Fulfillment Associate',
}

export const ROLE_OPTIONS = [
  { value: ROLES.OWNER, label: ROLE_NAMES[ROLES.OWNER] },
  { value: ROLES.ADMIN, label: ROLE_NAMES[ROLES.ADMIN] },
  { value: ROLES.GENERAL_MANAGER, label: ROLE_NAMES[ROLES.GENERAL_MANAGER] },
  { value: ROLES.MARKETING_MANAGER, label: ROLE_NAMES[ROLES.MARKETING_MANAGER] },
  { value: ROLES.SERVICE_MANAGER, label: ROLE_NAMES[ROLES.SERVICE_MANAGER] },
  { value: ROLES.SERVICE_AGENT, label: ROLE_NAMES[ROLES.SERVICE_AGENT] },
]

export const ROLE_NAMES_PROFILE_3 = {
  [ROLES.OWNER]: 'Owner',
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.GENERAL_MANAGER]: 'General Manager',
  [ROLES.MARKETING_MANAGER]: 'Commerce Manager',
  [ROLES.SERVICE_MANAGER]: 'Service Manager',
  [ROLES.SERVICE_AGENT]: 'Service Agent',
  [ROLES.FULFILLMENT_ASSOCIATE]: 'Fulfillment Associate',
}

export const ROLE_OPTIONS_PROFILE_3 = [
  { value: ROLES.OWNER, label: ROLE_NAMES_PROFILE_3[ROLES.OWNER] },
  { value: ROLES.ADMIN, label: ROLE_NAMES_PROFILE_3[ROLES.ADMIN] },
  { value: ROLES.GENERAL_MANAGER, label: ROLE_NAMES_PROFILE_3[ROLES.GENERAL_MANAGER] },
  { value: ROLES.MARKETING_MANAGER, label: ROLE_NAMES_PROFILE_3[ROLES.MARKETING_MANAGER] },
  { value: ROLES.SERVICE_MANAGER, label: ROLE_NAMES_PROFILE_3[ROLES.SERVICE_MANAGER] },
  { value: ROLES.SERVICE_AGENT, label: ROLE_NAMES_PROFILE_3[ROLES.SERVICE_AGENT] },
]

export const ONBOARDING_STEP = {
  AUTHENTICATED: 'authenticated',
  SELECTED_LOCATION: 'selected_location',
  MAPPED_CATEGORY: 'mapped_category',
  SYNCING: 'syncing',
  SYNC_COMPLETED: 'sync_completed',
  SYNC_FAILED: 'sync_failed',
}

export const PROFILE_TYPES = {
  ECOMMERCE: 1,
  MULTI_STORE: 2,
  CANNABIS: 3,
}
