export const EXPORT_TYPE = {
  ORDER: 'online_orders',
  COVA_POS_ORDER: 'cova_pos_orders',
  GREENLINE_POS_ORDER: 'greenline_pos_orders',
  PRODUCT: 'woocommerce_products',
  COVA_PRODUCT: 'cova_products',
  GREENLINE_PRODUCT: 'greenline_products',
  CUSTOMER: 'woocommerce_customers',
  COVA_CUSTOMER: 'cova_customers',
  GREENLINE_CUSTOMER: 'greenline_customers',
  INVENTORY: 'product_inventory',
  PRODUCT_PERFORMANCE: 'product_performance',
  EXPORT_TRANSFERS: 'export_transfers',
  EXPORT_ADJUSTMENTS: 'export_adjustments',
}
