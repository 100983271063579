export const AUTH_ACTION = {
  SIGN_UP: 'auth.SIGN_UP',
  SIGN_UP_SUCCESS: 'auth.SIGN_UP_SUCCESS',
  SIGN_IN: 'auth.SIGN_IN',
  SIGN_IN_RESET: 'auth.SIGN_IN_RESET',
  RESET_AUTH_STATE_ERROR: 'auth.RESET_AUTH_STATE_ERROR',
  SIGN_IN_WITH_TOKEN: 'auth.SIGN_IN_WITH_TOKEN',
  SIGN_IN_WITH_TOKEN_SUCCESS: 'auth.SIGN_IN_WITH_TOKEN_SUCCESS',
  SIGN_IN_WITH_TOKEN_MISSING_CLAIM: 'auth.SIGN_IN_WITH_TOKEN_MISSING_CLAIM',
  SIGN_IN_AGAIN: 'auth.SIGN_IN_AGAIN',
  SSO_SIGN_IN_WITH_TOKEN: 'auth.SSO_SIGN_IN_WITH_TOKEN',
  SSO_SIGN_IN_AFTER_VERIFY_EMAIL: 'auth.SSO_SIGN_IN_AFTER_VERIFY_EMAIL',
  SSO_SIGN_IN_WITH_TOKEN_SUCCESS: 'auth.SSO_SIGN_IN_WITH_TOKEN_SUCCESS',
  SSO_REDIRECT_SIGN_UP: 'auth.SSO_REDIRECT_SIGN_UP',
  SSO_SIGN_IN_WITH_TOKEN_MISSING_CLAIM: 'auth.SSO_SIGN_IN_WITH_TOKEN_MISSING_CLAIM',
  SSO_REDIRECT_SIGN_OUT_SUCCESS: 'auth.SSO_REDIRECT_SIGN_OUT_SUCCESS',
  SIGN_IN_SUCCESS: 'auth.SIGN_IN_SUCCESS',
  SIGN_IN_ERROR: 'auth.SIGN_IN_ERROR',
  SIGN_OUT: 'auth.SIGN_OUT',
  SIGN_OUT_SUCCESS: 'auth.SIGN_OUT_SUCCESS',
  CHECK_AUTH_STATE: 'auth.CHECK_AUTH_STATE',
  GET_PLUGINS: 'auth.GET_PLUGINS',
  GET_PLUGINS_SUCCESS: 'auth.GET_PLUGINS_SUCCESS',
  GET_PLUGINS_FAILED: 'auth.GET_PLUGINS_FAILED',
  JOIN_INVITATION: 'auth.JOIN_INVITATION',
}

export const signUp = (payload) => ({
  type: AUTH_ACTION.SIGN_UP,
  payload,
})

export const signIn = (payload) => ({
  type: AUTH_ACTION.SIGN_IN,
  payload,
})

export const resetSignIn = () => ({
  type: AUTH_ACTION.SIGN_IN_RESET,
})

export const resetAuthStateError = () => ({
  type: AUTH_ACTION.RESET_AUTH_STATE_ERROR,
})

export const signInWithToken = (payload) => ({
  type: AUTH_ACTION.SIGN_IN_WITH_TOKEN,
  payload,
})

export const signInWithTokenSuccess = (payload) => ({
  type: AUTH_ACTION.SIGN_IN_WITH_TOKEN_SUCCESS,
  payload,
})

export const signUpSuccess = () => ({
  type: AUTH_ACTION.SIGN_UP_SUCCESS,
})

export const ssoSignInWithToken = (payload) => ({
  type: AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN,
  payload,
})

export const ssoSignInAfterVerifyToken = (payload) => ({
  type: AUTH_ACTION.SSO_SIGN_IN_AFTER_VERIFY_EMAIL,
  payload,
})

export const ssoSignInWithTokenSuccess = (payload) => ({
  type: AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN_SUCCESS,
  payload,
})

export const ssoRedirectSignUp = (payload) => ({
  type: AUTH_ACTION.SSO_REDIRECT_SIGN_UP,
  payload,
})

export const ssoRedirectSignOutSuccess = () => ({
  type: AUTH_ACTION.SSO_REDIRECT_SIGN_OUT_SUCCESS,
})

export const ssoSignInWithTokenMissingClaim = (payload) => ({
  type: AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN_MISSING_CLAIM,
  payload,
})

export const signInWithTokenMissingClaim = (payload) => ({
  type: AUTH_ACTION.SIGN_IN_WITH_TOKEN_MISSING_CLAIM,
  payload,
})

export const signInAgain = (payload) => ({
  type: AUTH_ACTION.SIGN_IN_AGAIN,
  payload,
})

export const signInSuccess = (payload) => ({
  type: AUTH_ACTION.SIGN_IN_SUCCESS,
  payload,
})

export const signInError = (payload) => ({
  type: AUTH_ACTION.SIGN_IN_ERROR,
  payload,
})

export const signOut = (payload) => ({
  type: AUTH_ACTION.SIGN_OUT,
  payload,
})

export const signOutSuccess = () => ({
  type: AUTH_ACTION.SIGN_OUT_SUCCESS,
})

export const checkAuthState = (payload) => ({
  type: AUTH_ACTION.CHECK_AUTH_STATE,
  payload,
})

export const getPlugins = ({ onSuccess = () => {}, onError = () => {} }) => ({
  type: AUTH_ACTION.GET_PLUGINS,
  callbacks: {
    onSuccess,
    onError,
  },
})

export const joinInvitation = (payload) => ({
  type: AUTH_ACTION.JOIN_INVITATION,
  payload,
})

export default {
  signIn,
  resetSignIn,
  resetAuthStateError,
  signUp,
  signInAgain,
  signInWithToken,
  signInSuccess,
  signInWithTokenSuccess,
  ssoSignInWithToken,
  ssoSignInAfterVerifyToken,
  ssoSignInWithTokenSuccess,
  ssoRedirectSignUp,
  ssoRedirectSignOutSuccess,
  ssoSignInWithTokenMissingClaim,
  signInWithTokenMissingClaim,
  signInError,
  signOut,
  signOutSuccess,
  checkAuthState,
  getPlugins,
  joinInvitation,
}
