import { DEFAULT_TAX_CLASS } from '~/constants/settings'
import { capitalize } from '~/utils/utility'

export const sortTaxClasses = (arr) => {
  arr.sort((a, b) => {
    if (!a.name || !b.name) { return 0 }
    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1 }
    return 0
  })
  arr.forEach((item, i) => {
    if (item.slug === DEFAULT_TAX_CLASS) {
      arr.splice(i, 1)
      arr.unshift(item)
    }
  })

  return arr
}

export const convertTaxClassName = (taxClass) => {
  let name = ''
  if (taxClass.slug === DEFAULT_TAX_CLASS) {
    name = `${taxClass.name}s`
  } else {
    name = `${taxClass.name} rates`
  }

  return capitalize(name)
}

export const mappingTaxClasseOptions = (taxClasses) => (taxClasses || []).map((item) => ({ value: item.slug, label: convertTaxClassName(item) }))

export function createFullScreenControl(controlDiv, mapId) {
  const fullScreenButton = document.createElement('div')

  const fullscreenIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M16 3H22V9H20V5H16V3ZM2 3H8V5H4V9H2V3ZM20 19V15H22V21H16V19H20ZM4 19H8V21H2V15H4V19Z"></path>
    </svg>`

  const exitFullscreenIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18 7H22V9H16V3H18V7ZM8 9H2V7H6V3H8V9ZM18 17V21H16V15H22V17H18ZM8 15V21H6V17H2V15H8Z"></path>
    </svg>`

  fullScreenButton.innerHTML = fullscreenIcon

  fullScreenButton.style.cssText = `
      background-color: white; font-size: 20px; margin: 8px;
      cursor: pointer; display: flex; align-items: center;
      justify-content: center; width: 32px; height: 32px;
      line-height: 20px; user-select: none;`

  controlDiv.appendChild(fullScreenButton)

  let isFullScreen = false

  fullScreenButton.addEventListener('click', () => {
    if (!isFullScreen) {
      const currentMap = document.getElementById(mapId)
      if (currentMap.requestFullscreen) {
        currentMap.requestFullscreen()
      } else if (currentMap.mozRequestFullScreen) { // Firefox
        currentMap.mozRequestFullScreen()
      } else if (currentMap.webkitRequestFullscreen) { // Chrome, Safari and Opera
        currentMap.webkitRequestFullscreen()
      } else if (currentMap.msRequestFullscreen) { // IE/Edge
        currentMap.msRequestFullscreen()
      }
      fullScreenButton.innerHTML = exitFullscreenIcon
      isFullScreen = true
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen()
      }
      fullScreenButton.innerHTML = fullscreenIcon
      isFullScreen = false
    }
  })
}

export function createZoomControls(zoomControlDiv, map) {
  const zoomInButton = document.createElement('div')
  zoomInButton.classList.add('zoom-button')
  zoomInButton.innerHTML = '+'
  zoomInButton.style.backgroundColor = 'white'
  zoomInButton.style.marginRight = '8px'
  zoomInButton.style.fontSize = '20px'
  zoomInButton.style.cursor = 'pointer'
  zoomInButton.style.display = 'flex'
  zoomInButton.style.alignItems = 'center'
  zoomInButton.style.justifyContent = 'center'
  zoomInButton.style.width = '32px'
  zoomInButton.style.height = '32px'
  zoomInButton.style.userSelect = 'none'
  zoomControlDiv.appendChild(zoomInButton)

  const zoomOutButton = document.createElement('div')
  zoomOutButton.classList.add('zoom-button')
  zoomOutButton.innerHTML = '-'
  zoomOutButton.style.backgroundColor = 'white'
  zoomOutButton.style.fontSize = '30px'
  zoomOutButton.style.cursor = 'pointer'
  zoomOutButton.style.display = 'flex'
  zoomOutButton.style.alignItems = 'center'
  zoomOutButton.style.justifyContent = 'center'
  zoomOutButton.style.width = '32px'
  zoomOutButton.style.height = '32px'
  zoomOutButton.style.userSelect = 'none'
  zoomControlDiv.appendChild(zoomOutButton)

  zoomInButton.addEventListener('click', () => {
    map.setZoom(map.getZoom() + 1)
  })

  zoomOutButton.addEventListener('click', () => {
    map.setZoom(map.getZoom() - 1)
  })
}

export function createDrawingControl(controlDiv, map) {
  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: null,
    drawingControl: false,
    polygonOptions: {
      clickable: true,
      editable: true,
      draggable: true,
      geodesic: true,
      zIndex: 100,
      strokeWeight: 2,
    },
    circleOptions: {
      clickable: true,
      editable: true,
      draggable: true,
      geodesic: true,
      zIndex: 100,
      strokeWeight: 2,
    },
  })

  const draggingIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
  <path d="M402-40q-30 0-56-13.5T303-92L48-465l24-23q19-19 45-22t47 12l116 81v-383q0-17 11.5-28.5T320-840q17 0 28.5 11.5T360-800v537L212-367l157 229q5 8 14 13t19 5h278q33 0 56.5-23.5T760-200v-560q0-17 11.5-28.5T800-800q17 0 28.5 11.5T840-760v560q0 66-47 113T680-40H402Zm38-440v-400q0-17 11.5-28.5T480-920q17 0 28.5 11.5T520-880v400h-80Zm160 0v-360q0-17 11.5-28.5T640-880q17 0 28.5 11.5T680-840v360h-80ZM486-300Z"/>
  </svg>`
  const polygonIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
  <path d="M600-80v-100L320-320H120v-240h172l108-124v-196h240v240H468L360-516v126l240 120v-50h240v240H600ZM480-720h80v-80h-80v80ZM200-400h80v-80h-80v80Zm480 240h80v-80h-80v80ZM520-760ZM240-440Zm480 240Z"/>
  </svg>`
  const circleIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
  <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
  </svg>`

  drawingManager.setMap(map)

  const buttonContainer = document.createElement('div')
  buttonContainer.style.cssText = `
    display: flex; 
    align-items: center; 
    justify-content: flex-start; 
  `
  controlDiv.appendChild(buttonContainer)

  const panButton = document.createElement('div')
  panButton.classList.add('drawing-button')
  panButton.innerHTML = draggingIcon
  panButton.style.cssText = `
    background-color: white; 
    margin: 8px 2px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px; height: 24px;
    user-select: none;
  `
  buttonContainer.appendChild(panButton)

  const drawPolygonButton = document.createElement('div')
  drawPolygonButton.classList.add('drawing-button')
  drawPolygonButton.innerHTML = polygonIcon
  drawPolygonButton.style.cssText = `
    background-color: white;
    margin: 8px 2px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px; height: 24px;
    user-select: none;
  `
  buttonContainer.appendChild(drawPolygonButton)

  const drawCircleButton = document.createElement('div')
  drawCircleButton.classList.add('drawing-button')
  drawCircleButton.innerHTML = circleIcon
  drawCircleButton.style.cssText = `
    background-color: white;
    margin: 8px 2px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px; height: 24px;
    user-select: none;
  `
  function updateButtonColors(currentMode) {
    const panButtonIcon = panButton.querySelector('svg')
    const polygonButtonIcon = drawPolygonButton.querySelector('svg')
    const circleButtonIcon = drawCircleButton.querySelector('svg')

    panButtonIcon.setAttribute('fill', currentMode ? '#33363d66' : '#000000')
    polygonButtonIcon.setAttribute('fill', currentMode !== google.maps.drawing.OverlayType.POLYGON ? '#33363d66' : '#000000')
    circleButtonIcon.setAttribute('fill', currentMode !== google.maps.drawing.OverlayType.CIRCLE ? '#33363d66' : '#000000')
  }

  buttonContainer.appendChild(drawCircleButton)

  google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
    const currentMode = drawingManager.getDrawingMode()
    updateButtonColors(currentMode)
  })

  updateButtonColors(drawingManager.getDrawingMode())

  panButton.addEventListener('click', () => {
    drawingManager.setDrawingMode(null)
  })

  drawPolygonButton.addEventListener('click', () => {
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
  })

  drawCircleButton.addEventListener('click', () => {
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE)
  })
}
