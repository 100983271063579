import moment from 'moment'

export const PICKING_ERROR_MSG = {
  ALREADY_ASSIGNED: 'This task has been already assigned to a driver.',
  ALREADY_DONE_PICKING: 'Task is already done picking',
  ALREADY_NOTIFIED: 'Task is already notified',
  ALREADY_RESHIP: 'Task is already reship',
  ALREADY_PICKED_BY_SOMEONE: 'Someone is Picking',
  FAILED_LOAD_TASK: 'Failed to read task by id',
  FAILED_LOAD_ORDER: 'Failed to read order by id',
  INVALID_SHIPPING_ADDRESS: 'Your shipping address is invalid',
}

export const PICKING_INTERNAL_ERROR = {
  NONE: 0,
  SOMEONE_IS_PICKING: 1,
  INVALID_PICK_ACTION: 2,
  ALL_DRIVERS_BUSY: 1002,
  DUPLICATE_ASSIGN_TASK: 1003,
  INVALID_SHIPPING_ADDRESS: 1037,
  DRIVER_MAX_CAPACITY: 1046,
  ERROR_MESSAGE_FROM_BACKEND: 9999,
  NO_DRIVER_POOL_LINKED: 9998,
}

export const PICKING_REGULAR_METADATA_KEY = '_picking_regular_shipping'

export const PICKING_REGULAR_DEFAULT_METADATA = {
  currentlyPicking: false,
  donePicking: false,
  printed: false,
}

export const PICKING_ORDER_STATUS = {
  ON_HOLD: 'on-hold',
  PROCESSING: 'processing',
  PENDING_PAYMENT: 'pending',
}

export const NOTIFY_DRIVER_REQUIRED_FIELDS = ['shipping_info.address_1', 'pickup_location.address_1', 'order_number', 'shipping_fee']

export const FULFILLMENT_STATUS_STEPPERS = [
  {
    value: 'picking',
    label: 'Pending Picking',
  },
  {
    value: 'pickup',
    label: 'Pending Pickup',
  },
  {
    value: 'picked',
    label: 'Picked Up Orders',
  },
]

export const COURIER_VALUES = {
  ALL: 'all',
  CANTEC: 'cantec',
  CANFLEET: 'canfleet',
  CUSTOMER: 'customer',
  CURBSIDE: 'curbside',
  DRIVE_THRU: 'drive_through_pickup',
}

export const ORDER_COURIER_VALUES = {
  CANTEC_DELIVERY: 'cantec',
  CANFLEET_DELIVERY: 'canfleet_delivery',
  CUSTOMER_PICKUP: 'customer-pickup',
  CURBSIDE_PICKUP: 'curbside_pickup',
  DRIVE_THRU: 'drive_through_pickup',
}

export const SHIPPING_METHOD_VALUES = {
  ALL: 'all',
  CANTEC_DELIVERY: 'cantec_delivery',
  CANFLEET_DELIVERY: 'canfleet_delivery',
  CANTEC_LOCAL_PICKUP: 'cantec_local_pickup',
  CURBSIDE_PICKUP: 'curbside_pickup',
  BS_CANFLEET_LOCAL_SHIPPING: 'breadstack_canfleet_local_shipping',
  DRIVE_THRU: 'drive_through_pickup',
}

export const COURIER_OPTIONS = [
  {
    value: COURIER_VALUES.ALL,
    label: 'All Couriers',
  },
  {
    value: COURIER_VALUES.CANTEC,
    label: 'Cantec Couriers',
  },
  {
    value: COURIER_VALUES.CANFLEET,
    label: 'CanFleet Delivery',
  },
  {
    value: COURIER_VALUES.CUSTOMER,
    label: 'Store Pickup',
  },
  {
    value: COURIER_VALUES.CURBSIDE,
    label: 'Curbside Pickup',
  },
  {
    value: COURIER_VALUES.DRIVE_THRU,
    label: 'Drive thru',
  },
]

export const CALENDAR_EVENT_TYPE = {
  CANTEC: 'cantec',
  CUSTOMER_PICKUP: 'customer_pickup',
  REGULAR_SHIPPING: 'regular_shipping',
  ONFLEET: 'onfleet',
  CURBSIDE_PICKUP: 'curbside_pickup',
  CANFLEET_DELIVERY: 'canfleet_delivery',
}

export const PICKING_STATUS = {
  NEW: '',
  PICKING: 'picking',
  DONE_PICKING: 'done_picking',
  NOTIFIED: 'notified',
  DRIVER_PICKED: 'driver_picked',
  FAILED_TO_SHIP: 'failed_to_ship',
  SHIPPED: 'shipped',
}

export const CANFLEET_TRACKING_STATUS = {
  PENDING_PICKUP: 'pending_pickup',
  DELIVERING: 'delivering',
  RETURNING: 'returning',
  COMPLETED: 'completed',
  RETURNED: 'returned',
  CANCELED: 'canceled',
}

export const NOTIFY_MODE = {
  POOL_NOTIFY: 'pool notify',
  SPECIFIC_ASSIGNEE: 'specific assignee',
}

export const DEFAULT_POOL_OPTIONS = {
  is_public: false,
  is_courier: false,
  private_pools: [],
}

export const ACTION_STATUS = {
  NEW: 'NEW',
  SELECT_ONE: 'SELECT_ONE',
  SELECT_ALL: 'SELECT_ALL',
  CLEAR_ALL: 'CLEAR_ALL',
  PICKING: 'PICKING',
  NOTIFY_DRIVER: 'NOTIFY_DRIVER',
  UPDATE_FORCE_RELOAD: 'UPDATE_FORCE_RELOAD',
  BULK_PICKING: 'BULK_PICKING',
  BULK_NOTIFY_DRIVER: 'BULK_NOTIFY_DRIVER',
  FETCH_PRINTED_ORDERS: 'FETCH_PRINTED_ORDERS',
  OPEN_PRINTING_MODAL: 'OPEN_PRINTING_MODAL',
  CLOSE_PRINTING_MODAL: 'CLOSE_PRINTING_MODAL',
  SUMMARY: 'SUMMARY',
  TRACK_ORDER: 'TRACK_ORDER',
  COMPLETE_ORDER: 'COMPLETE_ORDER',
  PENDING_PICKUP_NEW: 'PENDING_PICKUP_NEW',
  COMPLETE_ORDERS_NEW: 'COMPLETE_ORDERS_NEW',
}

export const TIME_OF_COMPLETION_TYPE = {
  END_OF_DAY: 'end-of-day',
  SPECIFIC_TIME: 'specific-time',
}
export const DEFAULT_HEIGHT_LABEL_PRINT = 585

export const DEFAULT_HEIGHT_LETTER_PRINT = 1085

export const FULFILLMENT_STEPS = {
  PICKING: 'picking',
  PICK_UP: 'pickup',
  PICKED_UP: 'picked',
}

export const PICKING_CANTEC_PICKED_STATUS = {
  PICKED_UP: 1,
  COMPLETED: 2,
}

export const LAST_ACTIVITY = {
  DELIVERING: 'DELIVERING',
  IN_TRANSIT_FOR_RETURN: 'IN_TRANSIT_FOR_RETURN',
}

export const SHIPMENT_PROVIDER = {
  CANADA_POST: 'canada-post',
}

export const SHIPMENT_PROVIDERS = [
  {
    value: 'canada-post',
    label: 'Canada Post',
  },
]

// Regular Shipping Constants

export const REGULAR_SHIPPING_STEPS = {
  PICKING: 'picking',
  PENDING_DELIVERING: 'pending-delivering',
  TRACKING: 'tracking',
}

export const REGULAR_SHIPPING_ACTION_STATUS = {
  PICKING_NEW: 'PICKING_NEW',
  SELECT_ONE: 'SELECT_ONE',
  SELECT_ALL: 'SELECT_ALL',
  ClEAR_ALL: 'ClEAR_ALL',
  UPDATE_FORCE_RELOAD: 'UPDATE_FORCE_RELOAD',
  FETCH_PRINTED_ORDERS: 'FETCH_PRINTED_ORDERS',
  OPEN_PRINTING_MODAL: 'OPEN_PRINTING_MODAL',
  CLOSE_PRINTING_MODAL: 'CLOSE_PRINTING_MODAL',
  PENDING_DELIVERING_NEW: 'PENDING_DELIVERING_NEW',
  TRACKING_NEW: 'TRACKING_NEW',
  GENERATING_WAYBILL: 'GENERATING_WAYBILL',
  GENERATING_WAYBILL_SUCCESS: 'GENERATING_WAYBILL_SUCCESS',
  PRINTED_WAYBILL: 'PRINTED_WAYBILL',
  SELECT_GET_TRACKING_DETAIL_ORDER: 'SELECT_GET_TRACKING_DETAIL_ORDER',
  SELECT_ONE_PENDING_DELIVERY: 'SELECT_ONE_PENDING_DELIVERY',
  SELECT_ALL_PENDING_DELIVERY: 'SELECT_ALL_PENDING_DELIVERY',
  PENDING_DELIVERY_GENERATE_WAYBILL: 'PENDING_DELIVERY_GENERATE_WAYBILL',
  TRACKING_ORDER_GENERATE_WAYBILL: 'TRACKING_ORDER_GENERATE_WAYBILL',
  SHOW_PREPARE_PACKAGE_PANEL_TABLET: 'SHOW_PREPARE_PACKAGE_PANEL_TABLET',
}

export const REGULAR_SHIPPING_SERVICE = {
  CANADA_POST: 'canada-post',
}

export const REGULAR_SHIPPING_SERIVCES = [
  {
    value: REGULAR_SHIPPING_SERVICE.CANADA_POST,
    label: 'Canada Post, Xpresspost',
  },
]

export const WAYBILL_GENERATE_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const TABLE_SECTIONS_CONDITIONS = {
  isFuture: (order) => {
    const scheduledTime = moment(`${moment(order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    const diffFromToday = moment().startOf('day').diff(scheduledTime, 'days')
    if (diffFromToday < 0) {
      return true
    }
    return false
  },
  isToday: (order) => {
    const scheduledTime = moment(`${moment(order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    if (scheduledTime.isSame(new Date(), 'day')) {
      return true
    }
    return false
  },
  isLast7Days: (order) => {
    const scheduledTime = moment(`${moment(order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    const diffFromToday = moment().endOf('day').diff(scheduledTime, 'days')
    if (diffFromToday < 8 && diffFromToday >= 1) {
      return true
    }
    return false
  },
  isEarlier: (order) => {
    const scheduledTime = moment(`${moment(order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    const diffFromToday = moment().endOf('day').diff(scheduledTime, 'days')
    if (diffFromToday >= 8) {
      return true
    }
    return false
  },
}

export const TABLE_SECTIONS_COMPLETED_CONDITIONS = {
  isFuture: (order) => {
    const scheduledTime = moment(`${moment(order.date_completed_gmt || order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    const diffFromToday = moment().startOf('day').diff(scheduledTime, 'days')
    if (diffFromToday < 0) {
      return true
    }
    return false
  },
  isToday: (order) => {
    const scheduledTime = moment(`${moment(order.date_completed_gmt || order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    if (scheduledTime.isSame(new Date(), 'day')) {
      return true
    }
    return false
  },
  isLast7Days: (order) => {
    const scheduledTime = moment(`${moment(order.date_completed_gmt || order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    const diffFromToday = moment().endOf('day').diff(scheduledTime, 'days')
    if (diffFromToday < 8 && diffFromToday >= 1) {
      return true
    }
    return false
  },
  isEarlier: (order) => {
    const scheduledTime = moment(`${moment(order.date_completed_gmt || order.section_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    const diffFromToday = moment().endOf('day').diff(scheduledTime, 'days')
    if (diffFromToday >= 8) {
      return true
    }
    return false
  },
}

export const DEFAULT_TABLE_SECTIONS = [
  {
    condition: TABLE_SECTIONS_CONDITIONS.isToday,
    label: 'Today',
    value: 'today',
  },
  {
    condition: TABLE_SECTIONS_CONDITIONS.isLast7Days,
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    condition: TABLE_SECTIONS_CONDITIONS.isEarlier,
    label: 'Earlier',
    value: 'earlier',
  },
]

export const DEFAULT_TABLE_SECTIONS_COMPLETED = [
  {
    condition: TABLE_SECTIONS_COMPLETED_CONDITIONS.isToday,
    label: 'Today',
    value: 'today',
  },
  {
    condition: TABLE_SECTIONS_COMPLETED_CONDITIONS.isLast7Days,
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    condition: TABLE_SECTIONS_COMPLETED_CONDITIONS.isEarlier,
    label: 'Earlier',
    value: 'earlier',
  },
]

export const PAGINATION_LIMIT = 25
