import { EXPORT_TYPE } from '~/constants/notification'
import exportApi from '~/services/apis/export'

export const getCSVLink = (id) => exportApi.getLink(id)

export function handleExportType(type) {
  if (Object.values(EXPORT_TYPE).includes(type)) return true

  return false
}

export const exportTypePrefix = {
  [EXPORT_TYPE.ORDER]: 'Online orders',
  [EXPORT_TYPE.COVA_POS_ORDER]: 'Cova pos orders',
  [EXPORT_TYPE.GREENLINE_POS_ORDER]: 'Greenline pos orders',
  [EXPORT_TYPE.PRODUCT]: 'Woocommerce products',
  [EXPORT_TYPE.COVA_PRODUCT]: 'Cova products',
  [EXPORT_TYPE.GREENLINE_PRODUCT]: 'Greenline products',
  [EXPORT_TYPE.CUSTOMER]: 'Woocommerce customers',
  [EXPORT_TYPE.COVA_CUSTOMER]: 'Cova customers',
  [EXPORT_TYPE.GREENLINE_CUSTOMER]: 'Greenline customers',
  [EXPORT_TYPE.INVENTORY]: 'Product inventory',
  [EXPORT_TYPE.PRODUCT_PERFORMANCE]: 'Product performance',
  [EXPORT_TYPE.EXPORT_TRANSFERS]: 'Export transfers',
  [EXPORT_TYPE.EXPORT_ADJUSTMENTS]: 'Export adjustments',
}
