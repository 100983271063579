/* eslint-disable no-param-reassign */
import produce from 'immer'

import { AUTH_ACTION } from './action'
import { USER_ACTION } from '~/redux/actions/user'

import { mapAuthState } from '../utils'

export const AUTH_STATUS = {
  IDLE: 'IDLE',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

const initialState = {
  status: AUTH_STATUS.IDLE,
  error: null,
  data: null,
  plugins: {},
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case AUTH_ACTION.SIGN_IN_RESET:
      draft.data = null
      draft.error = null
      draft.status = AUTH_STATUS.IDLE
      break
    case AUTH_ACTION.RESET_AUTH_STATE_ERROR:
      draft.error = null
      break
    case AUTH_ACTION.SIGN_IN:
    case AUTH_ACTION.SIGN_UP:
    case AUTH_ACTION.SIGN_IN_WITH_TOKEN:
    case AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN:
      draft.data = null
      draft.error = null
      draft.status = AUTH_STATUS.PROCESSING
      break
    case AUTH_ACTION.SIGN_IN_SUCCESS:
    case AUTH_ACTION.SIGN_IN_WITH_TOKEN_SUCCESS:
    case AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN_SUCCESS:
      draft.data = mapAuthState(payload, draft.data)
      draft.error = null
      draft.status = AUTH_STATUS.SUCCESS
      break
    case AUTH_ACTION.SIGN_UP_SUCCESS:
      draft.error = null
      draft.status = AUTH_STATUS.SUCCESS
      break
    case AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN_MISSING_CLAIM:
      draft.data = null
      draft.status = AUTH_STATUS.IDLE
      break
    case AUTH_ACTION.SIGN_IN_WITH_TOKEN_MISSING_CLAIM:
      draft.data = null
      draft.error = null
      draft.status = AUTH_STATUS.IDLE
      break
    case AUTH_ACTION.SIGN_IN_ERROR:
      draft.data = null
      draft.error = payload
      draft.status = AUTH_STATUS.ERROR
      break
    case AUTH_ACTION.SSO_REDIRECT_SIGN_OUT_SUCCESS:
      draft.data = null
      draft.plugins = null
      draft.status = AUTH_STATUS.IDLE
      break
    case AUTH_ACTION.SIGN_OUT_SUCCESS:
      draft.data = null
      draft.error = null
      draft.plugins = null
      draft.status = AUTH_STATUS.IDLE
      break
    case USER_ACTION.REMOVE_STORE_SUCCESS: {
      const { organization_id: organizationId, store_id: removedStoreId } = payload
      const { [removedStoreId]: removed, ...restStores } = draft.data.stores
      draft.data.stores = restStores
      draft.data.orgs[organizationId].assignedStores = draft.data.orgs[organizationId].assignedStores.filter((s) => s !== removedStoreId)
      break
    }
    case AUTH_ACTION.GET_PLUGINS:
      draft.error = ''
      break
    case AUTH_ACTION.GET_PLUGINS_SUCCESS:
      draft.plugins = payload
      break
    case AUTH_ACTION.GET_PLUGINS_FAILED:
      draft.error = payload
      break

    default:
      break
  }
})

export default reducer
