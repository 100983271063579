// import moment from 'moment-timezone'
import { pickBy } from 'lodash'
import moment from 'moment'
import { PROFILE_TYPES } from '~/modules/auth/constants'
import { transformTypeOptions } from '~/utils/common'

export const SETTINGS_TYPE = {
  GENERAL: 'general',
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
  TAX_CLASS: 'tax-class',
  TIMEZONE: 'time-zone',
  NOTIFICATION: 'notifications',
  USERS: 'users',
}

export const WOO_SETTINGS_TAB = transformTypeOptions(pickBy(SETTINGS_TYPE, (x) => [
  SETTINGS_TYPE.SHIPPING,
  SETTINGS_TYPE.PAYMENT,
  SETTINGS_TYPE.TAX_CLASS,
  SETTINGS_TYPE.NOTIFICATION,
].includes(x)))

export const SETTINGS_TAB = transformTypeOptions(SETTINGS_TYPE)

export const SETTINGS_TAB_MINIFY = transformTypeOptions(pickBy(SETTINGS_TYPE, (x) => [
  SETTINGS_TYPE.GENERAL,
  SETTINGS_TYPE.TIMEZONE,
  SETTINGS_TYPE.NOTIFICATION,
].includes(x)))

// GENERAL SETTINGS

export const DEFAULT_PAGE_SIZE = 25

export const PAGE_SIZE_LIST = [10, 25, 50, 75, 100]

export const PAGE_SIZE_OPTIONS_RAW = PAGE_SIZE_LIST.map((x) => ({
  value: x,
  label: x,
}))

export const PAGE_SIZE_OPTIONS = PAGE_SIZE_LIST.map((x) => ({ value: x, label: `${x}${x === DEFAULT_PAGE_SIZE ? ' (Default) ' : ''}` }))

export const DEFAULT_FILTER_VIEW = 'open'

export const FILTER_VIEW_LIST = ['open', 'collapsed']

export const FILTER_VIEW_OPTIONS = transformTypeOptions(FILTER_VIEW_LIST)

export const DEFAULT_CALENDAR_VIEW = 'dayGridMonth'

export const CALENDAR_VIEW_OPTIONS = [{
  value: 'dayGridMonth',
  label: 'Monthly View',
}, {
  value: 'dayGridWeek',
  label: 'Weekly View',
}]

export const DEFAULT_PRINTING_SIZE = 'label'

export const PRINTING_SIZE_OPTIONS = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Label (6" x 4")',
    value: 'label',
  },
  {
    label: 'Letter',
    value: 'letter',
  },
]

// TIME ZONE SETTINGS

export const TIMEZONE_SETTING = {
  AUTO_DETECT: 'auto',
  MANUALLY: 'manual',
}

export const DEFAULT_TIMEZONE_SETTING = TIMEZONE_SETTING.AUTO_DETECT

// export const DEFAULT_AUTO_TIMEZONE = detectTimezoneInt()

export const DEFAULT_MANUALLY_TIMEZONE = '-8'

export const getManuallyTimeZoneOptions = () => (
  [{
    value: '+7',
    subLabel: 'UTC +7',
    label: 'Indochina Time',
    zone: 'Asia/Ho_Chi_Minh',
    country: 'Vietnam',
    utcOffset: moment().tz('Asia/Ho_Chi_Minh').utcOffset() / 60,
  },
  {
    value: '-3.5',
    subLabel: `UTC ${moment().tz('Canada/Newfoundland').utcOffset() / 60}`,
    label: moment().tz('Canada/Newfoundland').isDST() ? 'Newfoundland Daylight Time' : 'Newfoundland Standard Time',
    zone: 'Canada/Newfoundland',
    country: 'US & Canada',
    utcOffset: moment().tz('Canada/Newfoundland').utcOffset() / 60,
  },
  {
    value: '-4',
    subLabel: `UTC ${moment().tz('Canada/Atlantic').utcOffset() / 60}`,
    label: moment().tz('Canada/Newfoundland').isDST() ? 'Atlantic Daylight Time' : 'Atlantic Standard Time',
    zone: 'Canada/Atlantic',
    utcOffset: moment().tz('Canada/Atlantic').utcOffset() / 60,
  },
  {
    value: '-5',
    subLabel: `UTC ${moment().tz('Canada/Eastern').utcOffset() / 60}`,
    label: moment().tz('Canada/Newfoundland').isDST() ? 'Eastern Daylight Time' : 'Eastern Standard Time',
    zone: 'Canada/Eastern',
    utcOffset: moment().tz('Canada/Eastern').utcOffset() / 60,
  },
  {
    value: '-6',
    subLabel: `UTC ${moment().tz('Canada/Central').utcOffset() / 60}`,
    label: moment().tz('Canada/Central').isDST() ? 'Central Daylight Time' : 'Central Standard Time',
    zone: 'Canada/Central',
    utcOffset: moment().tz('Canada/Central').utcOffset() / 60,
  },
  {
    value: '-7',
    subLabel: `UTC ${moment().tz('Canada/Mountain').utcOffset() / 60}`,
    label: moment().tz('Canada/Central').isDST() ? 'Mountain Daylight Time' : 'Mountain Standard Time',
    zone: 'Canada/Mountain',
    utcOffset: moment().tz('Canada/Mountain').utcOffset() / 60,
  },
  {
    value: '-8',
    subLabel: `UTC ${moment().tz('Canada/Pacific').utcOffset() / 60}`,
    label: moment().tz('Canada/Central').isDST() ? 'Pacific Daylight Time' : 'Pacific Standard Time',
    zone: 'Canada/Pacific',
    utcOffset: moment().tz('Canada/Pacific').utcOffset() / 60,
  },
  {
    value: '-9',
    subLabel: `UTC ${moment().tz('US/Alaska').utcOffset() / 60}`,
    label: moment().tz('Canada/Central').isDST() ? 'Alaska Daylight Time' : 'Alaska Standard Time',
    zone: 'US/Alaska',
    utcOffset: moment().tz('US/Alaska').utcOffset() / 60,
  },
  {
    value: '-10',
    subLabel: `UTC ${moment().tz('US/Aleutian').utcOffset() / 60}`,
    label: moment().tz('Canada/Central').isDST() ? 'Hawaii-Aleutian Daylight Time' : 'Hawaii-Aleutian Standard Time',
    zone: 'US/Aleutian',
    utcOffset: moment().tz('US/Aleutian').utcOffset() / 60,
  }]
)

export const MANUALLY_TIMEZONE_OPTIONS = getManuallyTimeZoneOptions()

export const GENERAL_SETTING_TAB = {
  DISPLAY: 'display',
  FULFILLMENT: 'fulfillment',
}

export const NOTIFICATION_SETTINGS_TYPE = {
  // CANTEC_COURIERS: 'cantec-couriers',
  LOCAL_PICKUP: 'local-pickup',
  ONFLEET: 'onfleet',
  CANFLEET: 'canFleet',
  CURBSIDE: 'curbside-pickup',
}

export const NOTIFICATION_SHIPPING_METHODS = {
  CANTEC_DELIVERY: 'cantec_delivery',
  CANTEC_LOCAL_PICKUP: 'cantec_local_pickup',
  BSOF_LOCAL_SHIPPING: 'bsof_local_shipping',
  CANTEC: 'cantec',
  ONFLEET: 'onfleet',
  LOCAL_PICKUP: 'local_pickup',
  CURBSIDE_PICKUP: 'curbside_pickup',
  CANFLEET_DELIVERY: 'canfleet_delivery',
  BS_CANFLEET_LOCAL_SHIPPING: 'breadstack_canfleet_local_shipping',
  CANFLEET: 'canfleet',
}

export const NOTIFICATION_SETTINGS_TAB = transformTypeOptions(NOTIFICATION_SETTINGS_TYPE)

export const NOTIFICATION_ONFLEET_SETTINGS_TYPE = {
  CANTEC_COURIERS: 'cantec-couriers',
  LOCAL_PICKUP: 'local-pickup',
  ONFLEET: 'onfleet',
}

export const NOTIFICATION_ONFLEET_SETTINGS_TAB = transformTypeOptions(NOTIFICATION_ONFLEET_SETTINGS_TYPE)

export const DURATION_OPTIONS = [
  { label: '1 min', value: 1 },
  { label: '3 min', value: 3 },
  { label: '5 min', value: 5 },
]

export const REPEAT_OPTIONS = [
  { label: 'Never', value: -1 },
  { label: 'Every 5 mins', value: 5 },
  { label: 'Every 15 mins', value: 15 },
  { label: 'Every 30 mins', value: 30 },
  { label: 'Every hour', value: 60 },
]

export const GENERAL_SETTING_OPTIONS = transformTypeOptions(GENERAL_SETTING_TAB)

export const SHIPPING_TABS = {
  SHIPPING_ZONE: 'zone',
  SHIPPING_CLASS: 'classes',
  CANTEC_SHIPPING: 'cantec',
}

export const DEFAULT_TAX_CLASS = 'standard'

export const SHIPPING_OPTIONS = [
  { label: 'Shipping Zones', value: SHIPPING_TABS.SHIPPING_ZONE },
  { label: 'Shipping Classes', value: SHIPPING_TABS.SHIPPING_CLASS },
  { label: 'Cantec Shipping', value: SHIPPING_TABS.CANTEC_SHIPPING },
]

// UNIFIED PRODUCT
export const ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  GENERAL_MANAGER: 'general_manager',
  MARKETING_MANAGER: 'marketing_manager',
  SERVICE_MANAGER: 'service_manager',
  SERVICE_AGENT: 'service_agent',
  FULFILLMENT_ASSOCIATE: 'picking_operator',
}

export const ROLE_NAMES = {
  [ROLES.OWNER]: 'Owner',
  [ROLES.ADMIN]: 'Admin',
  [ROLES.GENERAL_MANAGER]: 'General Manager',
  [ROLES.MARKETING_MANAGER]: 'Marketing Manager',
  [ROLES.SERVICE_MANAGER]: 'Service Manager',
  [ROLES.SERVICE_AGENT]: 'Service Agent',
}

export const ROLE_OPTIONS = [
  { value: ROLES.ADMIN, label: ROLE_NAMES[ROLES.ADMIN] },
  { value: ROLES.GENERAL_MANAGER, label: ROLE_NAMES[ROLES.GENERAL_MANAGER] },
  { value: ROLES.MARKETING_MANAGER, label: ROLE_NAMES[ROLES.MARKETING_MANAGER] },
  { value: ROLES.SERVICE_MANAGER, label: ROLE_NAMES[ROLES.SERVICE_MANAGER] },
  { value: ROLES.SERVICE_AGENT, label: ROLE_NAMES[ROLES.SERVICE_AGENT] },
]

export const ROLE_NAMES_PROFILE_3 = {
  [ROLES.OWNER]: 'Owner',
  [ROLES.ADMIN]: 'Admin',
  [ROLES.GENERAL_MANAGER]: 'General Manager',
  [ROLES.MARKETING_MANAGER]: 'Commerce Manager',
  [ROLES.SERVICE_MANAGER]: 'Service Manager',
  [ROLES.SERVICE_AGENT]: 'Service Agent',
}

export const ROLE_OPTIONS_PROFILE_3 = [
  { value: ROLES.ADMIN, label: ROLE_NAMES_PROFILE_3[ROLES.ADMIN] },
  { value: ROLES.GENERAL_MANAGER, label: ROLE_NAMES_PROFILE_3[ROLES.GENERAL_MANAGER] },
  { value: ROLES.MARKETING_MANAGER, label: ROLE_NAMES_PROFILE_3[ROLES.MARKETING_MANAGER] },
  { value: ROLES.SERVICE_MANAGER, label: ROLE_NAMES_PROFILE_3[ROLES.SERVICE_MANAGER] },
  { value: ROLES.SERVICE_AGENT, label: ROLE_NAMES_PROFILE_3[ROLES.SERVICE_AGENT] },
]

export const PERMISSIONS = {
  DASHBOARD: 'dashboard',
  SALES_MANAGE: 'sales_manage',
  VISITORS: 'visitors',
  CUSTOMERS: 'customers',
  SALES_VIEW: 'sales_view',
  CHAT_RECORDS: 'chat_records',
  PRODUCTS: 'products',
  COUPONS: 'coupons',
  ANALYTICS: 'analytics',
  ORDERS: 'orders',
  LIVE_CHAT: 'live_chat',
  SETTINGS: 'settings',
}

export const PERMISSIONS_NAMES = {
  [PERMISSIONS.DASHBOARD]: 'Dashboard',
  [PERMISSIONS.SALES_MANAGE]: 'Sales (Manage)',
  [PERMISSIONS.VISITORS]: 'Visitors',
  [PERMISSIONS.CUSTOMERS]: 'Customers',
  [PERMISSIONS.SALES_VIEW]: 'Sales (View)',
  [PERMISSIONS.CHAT_RECORDS]: 'Chat Records',
  [PERMISSIONS.PRODUCTS]: 'Products',
  [PERMISSIONS.COUPONS]: 'Coupons',
  [PERMISSIONS.ANALYTICS]: 'Analytics',
  [PERMISSIONS.ORDERS]: 'Orders',
  [PERMISSIONS.LIVE_CHAT]: 'Live Chat',
  [PERMISSIONS.SETTINGS]: 'Settings',
}

export const PERMISSIONS_OPTIONS = [
  { value: PERMISSIONS.ANALYTICS, label: PERMISSIONS_NAMES[PERMISSIONS.ANALYTICS] },
  { value: PERMISSIONS.CHAT_RECORDS, label: PERMISSIONS_NAMES[PERMISSIONS.CHAT_RECORDS] },
  { value: PERMISSIONS.COUPONS, label: PERMISSIONS_NAMES[PERMISSIONS.COUPONS] },
  { value: PERMISSIONS.CUSTOMERS, label: PERMISSIONS_NAMES[PERMISSIONS.CUSTOMERS] },
  { value: PERMISSIONS.DASHBOARD, label: PERMISSIONS_NAMES[PERMISSIONS.DASHBOARD] },
  { value: PERMISSIONS.LIVE_CHAT, label: PERMISSIONS_NAMES[PERMISSIONS.LIVE_CHAT] },
  { value: PERMISSIONS.ORDERS, label: PERMISSIONS_NAMES[PERMISSIONS.ORDERS] },
  { value: PERMISSIONS.PRODUCTS, label: PERMISSIONS_NAMES[PERMISSIONS.PRODUCTS] },
  { value: PERMISSIONS.SALES_MANAGE, label: PERMISSIONS_NAMES[PERMISSIONS.SALES_MANAGE] },
  { value: PERMISSIONS.SALES_VIEW, label: PERMISSIONS_NAMES[PERMISSIONS.SALES_VIEW] },
  { value: PERMISSIONS.SETTINGS, label: PERMISSIONS_NAMES[PERMISSIONS.SETTINGS] },
  { value: PERMISSIONS.VISITORS, label: PERMISSIONS_NAMES[PERMISSIONS.VISITORS] },
]

export const ACCESS_PERMISSIONS = {
  [ROLES.GENERAL_MANAGER]: [
    PERMISSIONS.DASHBOARD,
    PERMISSIONS.CUSTOMERS,
    PERMISSIONS.PRODUCTS,
    PERMISSIONS.ORDERS,
    PERMISSIONS.SALES_MANAGE,
    PERMISSIONS.SALES_VIEW,
    PERMISSIONS.COUPONS,
    PERMISSIONS.LIVE_CHAT,
    PERMISSIONS.VISITORS,
    PERMISSIONS.CHAT_RECORDS,
    PERMISSIONS.ANALYTICS,
  ],
  [ROLES.MARKETING_MANAGER]: [
    PERMISSIONS.DASHBOARD,
    PERMISSIONS.CUSTOMERS,
    PERMISSIONS.PRODUCTS,
    PERMISSIONS.SALES_MANAGE,
    PERMISSIONS.SALES_VIEW,
    PERMISSIONS.COUPONS,
    PERMISSIONS.ANALYTICS,
  ],
  [ROLES.SERVICE_MANAGER]: [
    PERMISSIONS.DASHBOARD,
    PERMISSIONS.CUSTOMERS,
    PERMISSIONS.ORDERS,
    PERMISSIONS.SALES_VIEW,
    PERMISSIONS.COUPONS,
    PERMISSIONS.LIVE_CHAT,
    PERMISSIONS.VISITORS,
    PERMISSIONS.CHAT_RECORDS,
    PERMISSIONS.ANALYTICS,
  ],
  [ROLES.SERVICE_AGENT]: [
    PERMISSIONS.CUSTOMERS,
    PERMISSIONS.ORDERS,
    PERMISSIONS.SALES_VIEW,
    PERMISSIONS.COUPONS,
    PERMISSIONS.LIVE_CHAT,
    PERMISSIONS.VISITORS,
    PERMISSIONS.CHAT_RECORDS,
  ],
}

export const PERMISSIONS_DESCRIPTION = {
  [PROFILE_TYPES.MULTI_STORE]: {
    [ROLES.GENERAL_MANAGER]: 'General manager will have access to all the modules in both Service and Marketing. However, they do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
    [ROLES.MARKETING_MANAGER]: 'Marketing manager will have access to all the modules in Marketing. However, they do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
    [ROLES.SERVICE_MANAGER]: 'Service manager will have access to all the modules in Service . However, they do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
    [ROLES.SERVICE_AGENT]: 'Service agent will have access to certain modules in Service that help fulfill service support. They do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
  },
}

export const PERMISSIONS_DESCRIPTION_PROFILE_3 = {
  [PROFILE_TYPES.MULTI_STORE]: {
    [ROLES.GENERAL_MANAGER]: 'General manager will have access to all the modules in both Service and Marketing. However, they do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
    [ROLES.MARKETING_MANAGER]: 'Commerce manager will have access to all the modules in Marketing. However, they do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
    [ROLES.SERVICE_MANAGER]: 'Service manager will have access to all the modules in Service . However, they do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
    [ROLES.SERVICE_AGENT]: 'Service agent will have access to certain modules in Service that help fulfill service support. They do not have access to Setting, which means they can not configure the works, modify billing and invite users.',
  },
}

export const INTEGRATION_KEY_VALUE = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  WHATSAPP: 'whatsapp',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  COVA: 'cova',
}

export const INTEGRATION_KEY_NAME = {
  [INTEGRATION_KEY_VALUE.SHOPIFY]: 'Shopify',
  [INTEGRATION_KEY_VALUE.WOOCOMMERCE]: 'WooCommerce',
}

export const INTEGRATIONS_DESCRIPTION_LIST = {
  Ecommerce: 'Set up a two-way flow of data with your online store in just a few clicks. Then, use Breadstack as a central hub to engage and serve customers, manage operations, and make informed business decisions.',
  'Social media': 'Connect with customers on popular social media messaging apps without leaving Breadstack Live Chat.',
}

export const INTEGRATIONS_LIST = {
  Ecommerce: [
    { site: 'woocommerce', label: 'WooCommerce', description: 'The open-source eCommerce platform for WordPress' },
    { site: 'shopify', label: 'Shopify', description: 'An eCommerce platform for online stores and retail point-of-sale systems' },
  ],
  'Social media': [
    { site: 'facebook', label: 'Facebook', description: 'A social networking site for connecting with friends and others' },
    { site: 'instagram', label: 'Instagram', description: 'A popular social networking service for sharing photos and videos' },
    {
      site: 'whatsapp',
      label: 'WhatsApp',
      description: 'An instant messaging and voice-over-IP service',
      isDeveloping: true,
    },
  ],
  // Marketing: [{
  //   site: 'klaviyo',
  //   label: 'Klaviyo',
  //   description: 'This is a short description about the specific integration.',
  //   isDeveloping: true,
  // },
  // {
  //   site: 'mailchimp',
  //   label: 'Mailchimp',
  //   description: 'This is a short description about the specific integration.',
  //   isDeveloping: true,
  // }],
  // Email: [
  //   {
  //     site: 'gmail',
  //     label: 'Gmail',
  //     description: 'This is a short description about the specific integration.',
  //     isDeveloping: true,
  //   },
  //   {
  //     site: 'outlook',
  //     label: 'Outlook',
  //     description: 'This is a short description about the specific integration.',
  //     isDeveloping: true,
  //   },
  // ],
}

export const STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
}

export const STATUS_OPTIONS = [
  { value: STATUS.ACTIVE, label: 'Active' },
  { value: STATUS.PENDING, label: 'Pending' },
]

export const INTEGRATIONS_PROFILE3_TYPE = {
  AEROPAY: 'aeropay',
  COVAPAY: 'covapay',
  BLAZEPAY: 'blazepay',
  ALPINE: 'alpine',
  SPRINGBIG: 'springbig',
  MONERIES: 'moneris',
  ONFLEET: 'onfleet',
  PAYFIRMA: 'payfirma',
  SHIPSTATION: 'shipStation',
}

export const INTEGRATIONS_PROFILE3_NAME = {
  [INTEGRATIONS_PROFILE3_TYPE.AEROPAY]: 'Aeropay',
  [INTEGRATIONS_PROFILE3_TYPE.COVAPAY]: 'Cova Pay',
  [INTEGRATIONS_PROFILE3_TYPE.BLAZEPAY]: 'BlazePay',
  [INTEGRATIONS_PROFILE3_TYPE.ALPINE]: 'AlpineIQ',
  [INTEGRATIONS_PROFILE3_TYPE.SPRINGBIG]: 'Springbig',
  [INTEGRATIONS_PROFILE3_TYPE.MONERIES]: 'Moneris',
  [INTEGRATIONS_PROFILE3_TYPE.ONFLEET]: 'Onfleet',
  [INTEGRATIONS_PROFILE3_TYPE.PAYFIRMA]: 'Payfirma/Merrco',
  [INTEGRATIONS_PROFILE3_TYPE.SHIPSTATION]: 'ShipStation',
}

export const INTEGRATIONS_NEW_PROFILE3_LIST = {
  Fulfillment: {
    list: [
      { site: INTEGRATIONS_PROFILE3_TYPE.ONFLEET, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.ONFLEET], description: 'A platform for self-managing delivery and dispatch services' },
      { site: INTEGRATIONS_PROFILE3_TYPE.SHIPSTATION, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.SHIPSTATION], description: 'A multichannel Canadian shipping software application' },
    ],
    description: 'Your customers want smooth, speedy deliveries. Fulfillment management platforms help you keep up with their expectations.',
  },
  Loyalty: {
    list: [
      { site: INTEGRATIONS_PROFILE3_TYPE.ALPINE, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.ALPINE], description: 'Simplifies transactions with fast, secure payments' },
      { site: INTEGRATIONS_PROFILE3_TYPE.SPRINGBIG, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.SPRINGBIG], description: 'Links payments to customer rewards for enhanced loyalty' },
    ],
    description: 'Reward your customers for their loyalty with seamless integration of loyalty programs.',
  },
  Payment: {
    list: [
      { site: INTEGRATIONS_PROFILE3_TYPE.AEROPAY, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.AEROPAY], description: 'Simplify payments with a secure and seamless solution' },
      { site: INTEGRATIONS_PROFILE3_TYPE.BLAZEPAY, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.BLAZEPAY], description: 'Offers a robust platform for quick and secure transactions' },
      { site: INTEGRATIONS_PROFILE3_TYPE.COVAPAY, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.COVAPAY], description: 'Streamline transactions with a solution designed for retail efficiency' },
      { site: INTEGRATIONS_PROFILE3_TYPE.MONERIES, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.MONERIES], description: 'Safe, easy, and reliable online and in-store payment processing method' },
      { site: INTEGRATIONS_PROFILE3_TYPE.PAYFIRMA, label: INTEGRATIONS_PROFILE3_NAME[INTEGRATIONS_PROFILE3_TYPE.PAYFIRMA], description: 'One-stop merchant services partner for credit card processing' },
    ],
    description: 'Provide convenient, secure online payment options for your customers.',
  },
  'Social media': {
    list: [
      { site: 'facebook', label: 'Facebook', description: 'A social networking site for connecting with friends and others' },
      { site: 'instagram', label: 'Instagram', description: 'A popular social networking service for sharing photos and videos' },
      {
        site: 'whatsapp',
        label: 'WhatsApp',
        description: 'An instant messaging and voice-over-IP service',
        isDeveloping: true,
      },
    ],
    description: 'Connect with customers on popular social media messaging apps without leaving Breadstack Live Chat.',
  },
}

export const DEFAULT_TIME_SLOT_VALUE = {
  startTime: '09:00 AM',
  endTime: '09:00 PM',
  leadTime: '',
  id: new Date().getTime(),
}

export const SPECIAL_HOUR_REQUEST_FORMAT = 'YYYY-MM-DD'

export const SPECIAL_HOUR_DISPLAY_FORMAT = 'MMM D, YYYY'

export const TIME_HOUR_DISPLAY_FORMAT = 'h:mm A'

export const TIME_SLOT_OPTION_VALUE = {
  GENERATE_TIME_SLOTS: 'generate_time_slots',
  CUSTOMIZE_INDIVIDUAL_TIME_SLOTS: 'customize_individual_time_slots',
  NO_TIME_SLOT: 'no_time_slot',
}

export const TIME_SLOT_OPTION_LABEL = {
  [TIME_SLOT_OPTION_VALUE.GENERATE_TIME_SLOTS]: 'Generate time slots',
  [TIME_SLOT_OPTION_VALUE.CUSTOMIZE_INDIVIDUAL_TIME_SLOTS]: 'Customize individual time slots',
  [TIME_SLOT_OPTION_VALUE.NO_TIME_SLOT]: 'No time slot. The date slot is only available before the last order time.',
}

export const TIME_SLOT_OPTIONS = [
  { value: TIME_SLOT_OPTION_VALUE.GENERATE_TIME_SLOTS, label: TIME_SLOT_OPTION_LABEL[TIME_SLOT_OPTION_VALUE.GENERATE_TIME_SLOTS] },
  { value: TIME_SLOT_OPTION_VALUE.CUSTOMIZE_INDIVIDUAL_TIME_SLOTS, label: TIME_SLOT_OPTION_LABEL[TIME_SLOT_OPTION_VALUE.CUSTOMIZE_INDIVIDUAL_TIME_SLOTS] },
  { value: TIME_SLOT_OPTION_VALUE.NO_TIME_SLOT, label: TIME_SLOT_OPTION_LABEL[TIME_SLOT_OPTION_VALUE.NO_TIME_SLOT] },
]

export const TIME_PICKER_VALUE = {
  SPECIFIC_TIME_SLOT: 'specific_time_slot',
  ALL_DAY: 'all_day',
}

export const AUTO_SELECT_OPTION_VALUE = {
  FIRST_AVAILABLE_TIME_SLOT: 'first',
  ASAP: 'asap',
}

export const PAYMENT_STATUS = {
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not_connected',
}

export const PAYMENT_STATUS_OPTIONS = [
  { value: PAYMENT_STATUS.CONNECTED, label: 'Connected' },
  { value: PAYMENT_STATUS.NOT_CONNECTED, label: 'Not connected' },
]

export const FULFILLMENT_METHOD_OPTION = [
  { value: 'mail_order', label: 'Mail order', disabled: false },
  { value: 'delivery', label: 'Delivery', disabled: false },
  { value: 'pickup', label: 'Pickup', disabled: false },
  { value: 'curbside', label: 'Curbside', disabled: false },
]

export const DELIVERY_TAB = {
  DELIVERY_HOURS: 'delivery_hours',
  DELIVERY_ZONES: 'delivery_zones',
  ORDER_LIMIT: 'order_limit',
}

export const DELIVERY_TAB_OPTIONS = [
  { value: DELIVERY_TAB.DELIVERY_HOURS, label: 'Hours' },
  { value: DELIVERY_TAB.DELIVERY_ZONES, label: 'Delivery zones' },
  { value: DELIVERY_TAB.ORDER_LIMIT, label: 'Order limit' },
]

export const DEFAULT_UNIT_TYPE = {
  PERCENTAGE: 'percentage',
  FIX_AMOUNT: 'fixed_amount',
}

export const DEFAULT_UNIT_OPTIONS = [{
  label: 'Fix amount',
  value: DEFAULT_UNIT_TYPE.FIX_AMOUNT,
  unit: '$',
}, {
  label: 'Percentage',
  value: DEFAULT_UNIT_TYPE.PERCENTAGE,
  unit: '%',
}]

export const DELIVERY_ZONES_SETS = {
  DRAWN_ZONES: 'polygon',
  DISTANCE_RADIUS: 'radius',
  POSTAL_CODE: 'postal_code',
}
