export const FLAGS = Object.freeze({
  BS_DEBUG_MODE: 'bs-debug-mode',
  ACD_11119_HIDDEN_PRODUCT_TRASH_TAB_COVA_GREENLINE: 'acd-11119-hidden-product-tab-cova-greenline',
  BS_FULFILLMENT_POLLING: 'bs_fulfillment_polling',
  BS_ENABLE_COVA_PRODUCT: 'bs-enable-cova-product',
  BS_FILTER_DATE_DEFAULT_YESTERDAY: 'bs_filter_date_default_yesterday',
  BS_ACD_11142: 'bs_11142_cova_product_sale_price_edit',
  BS_11461: 'bs_11461_retain_location_and_period_for_user_session',
  BS_11801: 'bs_11801_set_order_note_to_public',
  BS_11583: 'bs_11583_default_location_setting_ui',
  BS_11554: 'bs_11554_promotion_banner_integration',
  BS_11569: 'bs_11569_bundle_discount_promotion',
  BS_10762: 'bs-customer-ui-facelifting',
  BS_11298: 'bs_11298_greenline_product_sale_price_edit',
  BS_11301: 'bs_11301_enable_edit_greenline_product',
  BS_11305: 'bs_11305_cova_edit_unavailable_pos_fields',

  // Flags for CS
  CS_7743: 'cs-7743-warning-redirect-out',
  CS_CSP_7619: 'cs-7589-custom-audio-notification',
})
