import {
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'

// import Cookies from 'js-cookie'
import Cookies from 'js-cookie'
import { clearAll, get, clear } from '~/utils/storage/localStorage'
import { clearAll as clearAllMemoryStorage, set as setMemoryStorage } from '~/utils/storage/memoryStorage'
import { clearNotificationSettingsIDB } from '~/utils/storage/indexedDB'
import { updateTimeZoneMoment } from '~/utils/datetime'
import pluginsApi from '~/services/apis/plugins'
import userApi from '~/services/apis/user'

import {
  AUTH_ACTION,
  signInSuccess,
  signInWithToken,
  signOutSuccess,
  signIn as signInAction,
  signInError,
  signOut as signOutAction,
  signInWithTokenSuccess,
  signInWithTokenMissingClaim,
  ssoSignInWithTokenSuccess,
  ssoSignInWithTokenMissingClaim,
  ssoRedirectSignOutSuccess,
  signUpSuccess,
} from './action'
import authApi from '../services'
import { syncedSignOut } from '../utils/syncedSignOut'
import { mapSettingData } from '~/utils/user'

import { AUTH_STORAGE_KEYS } from '~/constants/localStorage'
import { ERROR_CODES, formatFirebaseError } from '~/constants/errors'
import { COOKIE_KEYS, TIME_COOKIE_TOKEN_EXPIRATION } from '~/constants/auth'
import { SSO_COOKIE_DOMAIN } from '~/constants/environment'
import { decodeJwt } from '~/utils/jwt'
import logger from '~/utils/logger'

const clearInvitation = () => {
  clear(AUTH_STORAGE_KEYS.INVITATION_CODE)
  clear(AUTH_STORAGE_KEYS.INVITATION_EMAIL)
}

const findErrorObj = (arr) => (arr.find((element) => (element?.code !== undefined || element?.message !== undefined)) || {})

function* signUp({ payload }) {
  try {
    const {
      email, password, onSentEmail, isEmailVerify,
    } = payload
    const result = yield call(authApi.signUp, email, password, null, isEmailVerify)
    if (isEmailVerify && onSentEmail && !result?.emailVerified) {
      onSentEmail(result?.uid, email)
      yield put(signUpSuccess())
    } else {
      yield put(signInAction({
        ...payload, source: 'signup', isSignedUp: true, isEmailVerify,
      }))
    }
  } catch (e) {
    yield put(signInError(e))
  }
}

function* signIn({
  payload: {
    email, password, source, isSignedUp, is2FA, ...info
  },
}) {
  const invitationCode = get(AUTH_STORAGE_KEYS.INVITATION_CODE)
  const invitationEmail = get(AUTH_STORAGE_KEYS.INVITATION_EMAIL) || ''

  try {
    if (!isSignedUp && !is2FA) {
      logger.info('signIn()... sign in with email and password', email, isSignedUp, is2FA)
      yield call(authApi.signIn, email, password)
    }

    if (source === 'signup') {
      // call signUpWhenFbExist will be failed if user is exist in system and raise error
      yield call(authApi.signUpWhenFbExist, info)
      yield call(authApi.forceUpdateToken)
    }

    const claimsToken = yield call(authApi.getClaimsToken)
    if (!claimsToken?.claims?.is_breadstack && source !== 'signup') {
      yield put(signInError('User’s account has not been registered with Breadstack'))
      return
    }
    const dataToken = yield call(authApi.ssoAuth)
    setMemoryStorage(COOKIE_KEYS.DATA_TOKEN, dataToken)

    let user = yield call(authApi.get)

    if (user.email_verified && email.toLowerCase() === invitationEmail.toLowerCase() && !!invitationCode) {
      yield call(userApi.join, invitationCode)
      user = yield call(authApi.get)
      clearInvitation()
    }

    const { tz_setting, tz_timezone } = mapSettingData(user)
    updateTimeZoneMoment(tz_setting, tz_timezone)
    yield put(signInSuccess(user))
  } catch (e) {
    const error = typeof e === 'string' ? e : findErrorObj([e?.response?.data, e])

    if (error?.code === ERROR_CODES.INVITATION_EXPIRED) {
      clearInvitation()
    }
    yield call(authApi.signOut)
    // Cookies.remove(COOKIE_KEYS.CUSTOM_TOKEN, {
    //   domain: SSO_COOKIE_DOMAIN,
    // })
    // Cookies.remove(COOKIE_KEYS.REFRESH_TOKEN, {
    //   domain: SSO_COOKIE_DOMAIN,
    // })
    yield put(signInError(formatFirebaseError(error)))
  }
}

function* _ssoSignInAfterVerifyEmail({ payload: { customToken, onFinal } }) {
  try {
    yield call(authApi.signInWithCustomToken, customToken)
    const dataToken = yield call(authApi.ssoAuth)
    setMemoryStorage(COOKIE_KEYS.DATA_TOKEN, dataToken)
    const user = yield call(authApi.get)
    const { tz_setting, tz_timezone } = mapSettingData(user)
    updateTimeZoneMoment(tz_setting, tz_timezone)
    yield put(ssoSignInWithTokenSuccess(user))
    onFinal?.(user)
  } catch (e) {
    const error = findErrorObj([e?.response?.data, e])
    yield call(authApi.signOut)
    yield put(signInError(error))
    onFinal?.(undefined, error)
  }
}

function* joinInvitation({
  payload: {
    inviteCode, email, onSuccess, onError,
  },
}) {
  try {
    const decoded = decodeJwt(inviteCode)
    let user = yield call(authApi.get)
    if (decoded?.organization_id && email.toLowerCase() === decoded?.user_email) {
      /*
        * check if user already join the organization
        * if not join yet => call api join
      */
      if (!(user?.user_organizations || []).find((o) => o.organization_id === decoded.organization_id)) {
        yield call(userApi.join, inviteCode)
        user = yield call(authApi.get)
        yield put(signInSuccess(user))
      }
      if (onSuccess) onSuccess(user)
    }
  } catch (e) {
    const error = typeof e === 'string' ? e : findErrorObj([e?.response?.data, e])
    if (error?.code === ERROR_CODES.INVITATION_EXPIRED) {
      clearInvitation()
    }
    if (onError) onError(error)
  }
}

function* _signInWithToken({ payload: { refreshToken, onFinal } }) {
  try {
    const req = yield call(authApi.fetchRefreshToken, refreshToken)
    if (req.has_app) {
      yield call(authApi.signInWithCustomToken, req.custom_token)
      Cookies.set(COOKIE_KEYS.CUSTOM_TOKEN, req.custom_token, { expires: TIME_COOKIE_TOKEN_EXPIRATION, domain: SSO_COOKIE_DOMAIN })
      Cookies.set(COOKIE_KEYS.REFRESH_TOKEN, req.refresh_token, { expires: TIME_COOKIE_TOKEN_EXPIRATION, domain: SSO_COOKIE_DOMAIN })
      const user = yield call(authApi.get)
      const { tz_setting, tz_timezone } = mapSettingData(user)
      updateTimeZoneMoment(tz_setting, tz_timezone)
      yield put(signInWithTokenSuccess(user))
      onFinal?.(user)
    } else {
      yield call(authApi.signOut)
      // syncedSignOut()
      clearAll()
      clearAllMemoryStorage()
      clearNotificationSettingsIDB()
      updateTimeZoneMoment()
      yield put(signOutSuccess())
      yield put(signInWithTokenMissingClaim({ email: req.email }))
      onFinal?.({ email: req.email })
    }
  } catch (e) {
    const error = findErrorObj([e?.response?.data, e])

    if (error.code === ERROR_CODES.INVITATION_EXPIRED) {
      clearInvitation()
    }
    yield call(authApi.signOut)
    yield put(signInError(error))
    onFinal?.(undefined, error)
  }
}

function* _ssoSignInWithToken({ payload: { customToken, onFinal } }) {
  try {
    yield call(authApi.signInWithCustomToken, customToken)
    const user = yield call(authApi.get)
    const { tz_setting, tz_timezone } = mapSettingData(user)
    updateTimeZoneMoment(tz_setting, tz_timezone)
    yield put(ssoSignInWithTokenSuccess(user))
    onFinal?.(user)
  } catch (e) {
    const error = findErrorObj([e?.response?.data, e])

    if (error.code === ERROR_CODES.INVITATION_EXPIRED) {
      clearInvitation()
    }
    yield call(authApi.signOut)
    yield put(signInError(error))
    onFinal?.(undefined, error)
  }
}

function* _ssoRedirectSignUp({ payload: { email, onFinal } }) {
  try {
    yield call(authApi.signOut)
    clearAll()
    clearAllMemoryStorage()
    clearNotificationSettingsIDB()
    updateTimeZoneMoment()
    yield put(ssoRedirectSignOutSuccess())
    yield put(ssoSignInWithTokenMissingClaim({ email }))
    onFinal?.({ email })
  } catch (e) {
    const error = findErrorObj([e?.response?.data, e])

    if (error.code === ERROR_CODES.INVITATION_EXPIRED) {
      clearInvitation()
    }
    yield call(authApi.signOut)
    yield put(signInError(error))
    onFinal?.(undefined, error)
  }
}

function* _signInAgain({ payload }) {
  try {
    yield call(authApi.signOut)
    Cookies.remove(COOKIE_KEYS.CUSTOM_TOKEN, {
      domain: SSO_COOKIE_DOMAIN,
    })
    Cookies.remove(COOKIE_KEYS.REFRESH_TOKEN, {
      domain: SSO_COOKIE_DOMAIN,
    })
    syncedSignOut()
    clearAll()
    clearNotificationSettingsIDB()
    updateTimeZoneMoment()
    yield put(signInWithToken(payload))
  } catch (e) {
    payload?.onFinal?.(undefined, e)
  }
}

function* signOut() {
  try {
    yield call(authApi.signOut)
    syncedSignOut()
    clearAll()
    clearAllMemoryStorage()
    clearNotificationSettingsIDB()
    updateTimeZoneMoment()
    yield put(signOutSuccess())
    yield call(authApi.ssoClearSession)
  } catch (e) {
    // TODO: what should we do here?
  }
}

function* checkAuthState({ payload }) {
  try {
    const currentUser = yield call(authApi.getCurrentUser)
    if (!currentUser) {
      logger.info('checkAuthState()... sign out when can not get current user')
      yield put(signOutAction())
      return
    }

    const user = yield call(authApi.get)
    yield put(signInSuccess(user))
    if (payload?.onSuccess) payload.onSuccess()
  } catch (e) {
    logger.info('logout', e)
    yield put(signOutAction())
    if (payload?.onError) payload.onError(e)
  }
}

export function* getPlugins(action) {
  try {
    const response = yield call(pluginsApi.get)
    yield put({
      type: AUTH_ACTION.GET_PLUGINS_SUCCESS,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: AUTH_ACTION.GET_PLUGINS_FAILED,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

function* watchSignIn() {
  yield takeLatest(AUTH_ACTION.SIGN_IN, signIn)
}

function* watchSignInWithToken() {
  yield takeLatest(AUTH_ACTION.SIGN_IN_WITH_TOKEN, _signInWithToken)
}

function* watchSSOSignInWithToken() {
  yield takeLatest(AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN, _ssoSignInWithToken)
}

function* watchSSOSignInAfterVerifyEmail() {
  yield takeLatest(AUTH_ACTION.SSO_SIGN_IN_AFTER_VERIFY_EMAIL, _ssoSignInAfterVerifyEmail)
}

function* watchSSORedirectSignUp() {
  yield takeLatest(AUTH_ACTION.SSO_REDIRECT_SIGN_UP, _ssoRedirectSignUp)
}

function* watchSignInAgain() {
  yield takeLatest(AUTH_ACTION.SIGN_IN_AGAIN, _signInAgain)
}

function* watchSignOut() {
  yield takeLatest(AUTH_ACTION.SIGN_OUT, signOut)
}

function* watchAuthState() {
  yield takeLatest(AUTH_ACTION.CHECK_AUTH_STATE, checkAuthState)
}

function* watchGetPlugins() {
  yield takeLatest(AUTH_ACTION.GET_PLUGINS, getPlugins)
}

function* watchSignUp() {
  yield takeLatest(AUTH_ACTION.SIGN_UP, signUp)
}

function* watchJoinInvitation() {
  yield takeLatest(AUTH_ACTION.JOIN_INVITATION, joinInvitation)
}

export default function* auth() {
  yield fork(watchSignUp)
  yield fork(watchSignIn)
  yield fork(watchSignInWithToken)
  yield fork(watchSSOSignInAfterVerifyEmail)
  yield fork(watchSSOSignInWithToken)
  yield fork(watchSSORedirectSignUp)
  yield fork(watchSignInAgain)
  yield fork(watchSignOut)
  yield fork(watchAuthState)
  yield fork(watchGetPlugins)
  yield fork(watchJoinInvitation)
}
